import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AccountService, HttpRequestInterceptor } from './services';
import { EXPORTS, IMPORTS, GUARDS } from './index';

@NgModule({
  imports: IMPORTS,
  providers: [
    AccountService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    ...GUARDS,
  ],
  exports: EXPORTS,
})
export class CoreInitialModule {}
