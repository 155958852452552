export const TimeViews = [
  {
    id: 1,
    type: 'event.time',
    value: 'hour',
  },
  {
    id: 2,
    type: '@timestamp',
    value: 'day',
  },
  {
    id: 3,
    type: '@timestamp',
    value: 'week',
  },
  {
    id: 4,
    type: '@timestamp',
    value: 'month',
  },
];
