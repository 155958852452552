export const MatrixTypes = {
  0: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  1: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  2: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  3: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  4: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  5: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  6: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  7: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  8: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  9: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  10: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  11: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  12: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  13: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  14: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  15: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  16: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  17: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  18: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  19: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  20: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  21: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  22: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
  23: {
    id: 0,
    avg_occupancy: 'x',
    previous_avg_occupancy: 0,
  },
};
