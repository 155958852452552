import {
  LoadAccountAction,
  LoadAccountSuccessfulAction,
  LoadAccountSubscriptionAction,
  LoadAccountSubscriptionSuccessfulAction,
  LoadAccountSubscriptionFailedAction,
} from './../actions';
import { createReducer, on } from '@ngrx/store';
import { IAccount, Pack } from '@spaceti/models';

export const initialState: Pack<IAccount> = {
  loading: false,
  data: null,
};

const reducerFce = createReducer(
  initialState,
  on(LoadAccountAction, (state, action) => {
    return { ...state, loading: true };
  }),
  on(LoadAccountSuccessfulAction, (state, action) => ({
    ...state,
    loading: false,
    data: {
      ...state.data,
      ...action.account,
    },
  })),
  on(LoadAccountSubscriptionAction, (state, action) => {
    return { ...state, loading: true };
  }),
  on(LoadAccountSubscriptionSuccessfulAction, (state, action) => ({
    ...state,
    loading: false,
    data: {
      ...state.data,
      subscription: {
        ...action.subscription,
        expiration_date: action.subscription?.expiration_date
          ? new Date(action.subscription.expiration_date).getTime()
          : null,
        expired: action.subscription?.expiration_date
          ? new Date(action.subscription.expiration_date).getTime() - new Date().getTime() < 0
          : false,
      },
    },
  })),
  on(LoadAccountSubscriptionFailedAction, (state, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        expired: false,
      },
    };
  })
);
export function accountReducer(state, action) {
  return reducerFce(state, action);
}
