export const Tooltips = {
  projectScope: [
    {
      projectScopeComment: 'purchase-order-tooltip-project-scope-comment',
    },
  ],
  customerInformation: [
    {
      customerName: 'purchase-order-tooltip-customer-name',
    },
  ],

  generalInformation: [
    {
      customerAccount: 'purchase-order-tooltip-customer-account',
      spaceId: 'purchase-order-tooltip-space-id',
      deploymentDate: 'purchase-order-tooltip-deployment-date',
      trainingDate: 'purchase-order-tooltip-training-date',
      launchDate: 'purchase-order-tooltip-launch-date',
    },
  ],

  deploymentSpecification: [
    {
      deploymentNumFloors: 'purchase-order-tooltip-deployment-num-floors',
      deploymentSizeOfWorkspace:
        'purchase-order-tooltip-deployment-size-of-workspace',
      deploymentFloorPlansUpToDate:
        'purchase-order-tooltip-deployment-floor-plans-uptodate',
    },
  ],

  solution: [
    {
      occupancyAnalyticsComment:
        'purchase-order-tooltip-occupancy-analytics-comment',
      occupancyAnalyticsAttach:
        'purchase-order-tooltip-occupancy-analytics-attach',
      smartParkingComment: 'purchase-order-tooltip-smart-parking-comment',
      smartParkingAttach: 'purchase-order-tooltip-smart-parking-attach',
      wellbeingControlAndAnalyticsComment:
        'purchase-order-tooltip-wellbeing-comment',
      indoorNavigation: 'purchase-order-tooltip-indoor-navigation',
      indoorNavigationAttach: 'purchase-order-tooltip-indoor-navigation-attach',
      comment: 'purchase-order-tooltip-solution-comment',
    },
  ],

  hubSpecification: [
    {
      hubSpecConnectivityIssues:
        'purchase-order-tooltip-hub-connectivity-issues',
      hubSpecPermanentWifiConnection:
        'purchase-order-tooltip-hub-wifi-connection',
      hubSpecEthernetConnection:
        'purchase-order-tooltip-hub-ethernet-connection',
      hubSpecRadioSignalObstacles: 'purchase-order-tooltip-hub-radio-obstacles',
      hubSpecFloorplanAttachment:
        'purchase-order-tooltip-hub-floorplan-attachment',
      comment: 'purchase-order-tooltip-hub-comment',
    },
  ],

  hardware: [
    {
      occupancyChairSensor: 'purchase-order-tooltip-occupancy-chair-sensor',
      occupancyPresenceSensorDesk:
        'purchase-order-tooltip-occupancy-presence-sensor-desk',
      occupancyPresenceSensorRoom:
        'purchase-order-tooltip-occupancy-presence-sensor-room',
      occupancyParkingSensor: 'purchase-order-tooltip-occupancy-parking-sensor',
      airQualitySensor: 'purchase-order-tooltip-air-quality-sensor',
      wellbeingSensor: 'purchase-order-tooltip-wellbeing-sensor',
      indoorPositioningSensor:
        'purchase-order-tooltip-indoor-positioning-sensor',
    },
  ],

  software: [
    {
      smartBuildingSoftwarePlatform:
        'purchase-order-tooltip-smart-building-software-platform',
      smartBuildingMobileApp:
        'purchase-order-tooltip-smart-building-mobile-app',
      sdkForLocalisation: 'purchase-order-tooltip-sdk-for-localisation',
    },
  ],
};
