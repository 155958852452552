import { UntypedFormGroup } from '@angular/forms';

export function uniqueItemValidator(listToCheck: string, itemToCheck: string) {
  return (formGroup: UntypedFormGroup) => {
    const list = formGroup.controls[listToCheck];
    const uniqueItem = formGroup.controls[itemToCheck];

    if (uniqueItem.errors && !uniqueItem.errors.notUnique) {
      return;
    }

    if (list.value.some((item: string) => item === uniqueItem.value)) {
      uniqueItem.setErrors({ notUnique: true });
    } else {
      uniqueItem.setErrors(null);
    }
  };
}
