import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap, tap } from 'rxjs/operators';

import * as actions from '../actions';
import { AccountService } from '../services/account.service';
import { LocationService } from '@spaceti/core/common';
import { forkJoin, of } from 'rxjs';

@Injectable()
export class InitialEffects {
  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private locationService: LocationService
  ) {}

  loadRuntimeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadRuntimeInfoAction),
      switchMap((action) =>
        forkJoin([this.accountService.fetchRuntimeInfo(), this.accountService.loadRoles()]).pipe(
          mergeMap(([runtime, roles]) => {
            if (runtime.success && roles.success) {
              return [
                actions.SetUserRolesAction({ roles: roles.data }),
                actions.SetRuntimeInfoAction({
                  runtimeInfo: {
                    ...runtime.data,
                    role: roles.data.find((role) => role.id === runtime.data.role_id),
                  },
                }),
              ];
            } else {
              this.locationService.redirectToLogin(action.spaceId);
            }
          }),
          catchError((error) => {
            this.locationService.redirectToLogin(action.spaceId);
            return [];
          })
        )
      )
    )
  );

  loadAccountInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadAccountAction),
      switchMap((action) => this.accountService.loadAccount()),
      map((data) => {
        if (data.success) {
          return actions.LoadAccountSuccessfulAction({
            account: data.data,
          });
        } else {
          return actions.LoadAccountFailedAction({
            error: data.error,
          });
        }
      })
    )
  );

  loadAccountSuccessfulAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadAccountSuccessfulAction),
      switchMap((action) => [
        actions.LoadAccountSubscriptionAction({
          account_id: action.account.id,
        }),
      ])
    )
  );

  loadAccountSubscriptionInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadAccountSubscriptionAction),
      switchMap((action) => this.accountService.loadAccountSubscription(action.account_id)),
      map((data) => {
        if (data) {
          return actions.LoadAccountSubscriptionSuccessfulAction({
            subscription: data.data,
          });
        } else {
          return actions.LoadAccountSubscriptionFailedAction({
            error: data.error,
          });
        }
      })
    )
  );

  loadAnalyticsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadAnalyticsInfoAction),
      switchMap((action) => this.accountService.loadAnalyticsInfo()),
      map((data) => {
        if (data.success) {
          return actions.LoadAnalyticsInfoSuccessfulAction({
            analytics: data.data,
          });
        } else {
          return actions.LoadAnalyticsInfoFailedAction({
            error: data.error,
          });
        }
      })
    )
  );
}
