export class RolePermissions {
  static canEdit(manager: boolean, isSelf: boolean, isRole: string): boolean {
    if (isSelf || isRole === 'admin' || isRole === 'owner') return false;
    else return manager;
  }

  static canEditRole(
    manager: boolean,
    isSelf: boolean,
    isRole: string
  ): boolean {
    if (isSelf || isRole === 'admin' || isRole === 'owner') return false;
    else return manager;
  }

  static canDelete(manager: boolean, isSelf: boolean, isRole: string): boolean {
    if (isSelf || isRole === 'admin' || isRole === 'owner') return false;
    else return manager;
  }
}
