import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreInitialModule } from '@spaceti/core/initial';
import { EnvironmentsModule } from '@spaceti/environments';
import { CoreCommonModule } from '@spaceti/core/common';
import { RouterModule } from '@angular/router';
import { routes } from '../account.routes';
import { environment, firebaseConfig } from '@spaceti/environments';
import { AppComponent } from './components';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics, ScreenTrackingService } from '@angular/fire/analytics';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreInitialModule,
    EnvironmentsModule,
    CoreCommonModule,
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      enableTracing: environment.production ? false : true,
    }),
    provideFirebaseApp(() => initializeApp(firebaseConfig, 'spaceti')),
    provideAnalytics(() => getAnalytics(getApp('spaceti'))),
    /**ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),*/
  ],
  declarations: [AppComponent],
  providers: [ScreenTrackingService],
  bootstrap: [AppComponent],
})
export class AppModule {}
