export const DayTypes = {
  ['Day']: [0, 0],
};

export const WeekTypes = [
  {
    ['Monday']: [0, 0],
  },
  {
    ['Tuesday']: [0, 25],
  },
  {
    ['Wednesday']: [0, 50],
  },
  {
    ['Thursday']: [0, 75],
  },
  {
    ['Friday']: [0, 100],
  },
  {
    ['Saturday']: [0, 125],
  },
  {
    ['Sunday']: [0, 150],
  },
];
