import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { AnalyticsInfo, IAccount, Pack, RuntimeInfo, UserRoleModel } from '@spaceti/models';
import { runtimeInfoReducer } from './runtime-info.reducer';
import { accountReducer } from './account.reducer';
import { userRolesReducer } from './user-roles.reducer';
import { analyticsReducer } from './anlytics.reducer';

export const reducers: ActionReducerMap<{
  userRoles: Array<UserRoleModel>;
  runtimeInfo: RuntimeInfo;
  router: RouterReducerState;
  account: Pack<IAccount>;
  analytics_info: Pack<AnalyticsInfo>;
}> = {
  userRoles: userRolesReducer,
  runtimeInfo: runtimeInfoReducer,
  router: routerReducer,
  account: accountReducer,
  analytics_info: analyticsReducer,
};
