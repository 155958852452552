import { createAction, props } from '@ngrx/store';
import { AccountSubscription, IAccount } from '@spaceti/models';
import { type } from '@spaceti/utils';

export const AccountActionTypes = {
  LoadAccountAction: type('[Guard] Load Account info'),
  LoadAccountSuccessfulAction: type('[Guard] Load Account info successfull'),
  LoadAccountFailedAction: type('[Guard] Load account info failed'),
  LoadAccountSubscriptionAction: type('[Guard] Load Account subscription'),
  LoadAccountSubscriptionSuccessfulAction: type(
    '[Guard] Load Account subscription info successfull'
  ),
  LoadAccountSubscriptionFailedAction: type(
    '[Guard] Load account subscription info failed'
  ),
};

export const LoadAccountAction = createAction(
  AccountActionTypes.LoadAccountAction
);

export const LoadAccountSuccessfulAction = createAction(
  AccountActionTypes.LoadAccountSuccessfulAction,
  props<{ account: IAccount }>()
);

export const LoadAccountFailedAction = createAction(
  AccountActionTypes.LoadAccountFailedAction,
  props<{ error: any }>()
);

export const LoadAccountSubscriptionAction = createAction(
  AccountActionTypes.LoadAccountSubscriptionAction,
  props<{ account_id: number }>()
);

export const LoadAccountSubscriptionSuccessfulAction = createAction(
  AccountActionTypes.LoadAccountSubscriptionSuccessfulAction,
  props<{
    subscription: AccountSubscription;
  }>()
);

export const LoadAccountSubscriptionFailedAction = createAction(
  AccountActionTypes.LoadAccountSubscriptionFailedAction,
  props<{ error: any }>()
);
