import { SetRuntimeInfoAction } from './../actions';
import { createReducer, on } from '@ngrx/store';
import { Auth0RuntimeInfo, RuntimeInfo } from '@spaceti/models';

export const initialState: RuntimeInfo = null;

const reducerFce = createReducer(
  initialState,
  on(SetRuntimeInfoAction, (state, action) => {
    return { ...action.runtimeInfo };
  })
);
export function runtimeInfoReducer(state, action) {
  return reducerFce(state, action);
}
