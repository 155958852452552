import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocationService } from '@spaceti/core/common';
import { Observable } from 'rxjs';

import * as actions from './../actions';
import { AccountService } from '../services';
import { InitialAppState } from '../models';

@Injectable()
export class LoginGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private locationService: LocationService,
    private store: Store<InitialAppState>
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> {
    //  this.location.replaceState(window.location.pathname);
    const token = this.router.getCurrentNavigation()?.extractedUrl?.queryParams?.accessToken;
    const integration = this.router.getCurrentNavigation()?.extractedUrl?.queryParams?.integration;

    if (integration) {
      localStorage.setItem('integration', integration);
    }

    if (token) {
      if (this.accountService.login(token)) {
        this.store.dispatch(actions.LoadRuntimeInfoAction({}));

        const location =
          this.router
            .getCurrentNavigation()
            .extractedUrl?.root?.children?.primary?.segments?.map((segment) => segment.path) || [];

        return this.router.createUrlTree(location, {
          queryParams: {},
        });
      } else {
        this.locationService.redirectToLogin();
        return false;
      }
    } else if (this.accountService.isSignedIn()) {
      this.store.dispatch(actions.LoadRuntimeInfoAction({}));
      this.store.dispatch(actions.LoadAccountAction());
      this.store.dispatch(actions.LoadAnalyticsInfoAction());
      return true;
    }
    this.locationService.redirectToLogin();
    return false;
  }

  canActivate() {
    return true;
    //return this.router.createUrlTree(['realtime-view/map'], { queryParams: {} });
  }
}
