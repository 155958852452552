import { createAction, props } from '@ngrx/store';
import { RuntimeInfo } from '@spaceti/models';
import { type } from '@spaceti/utils';

export const RuntimeInfoActionTypes = {
  SetRuntimeInfoAction: type('[Effect] Set runtime info'),
  LoadRuntimeInfoAction: type('[Guard] Load Runtime info'),
};

export const SetRuntimeInfoAction = createAction(
  RuntimeInfoActionTypes.SetRuntimeInfoAction,
  props<{ runtimeInfo: RuntimeInfo }>()
);

export const LoadRuntimeInfoAction = createAction(
  RuntimeInfoActionTypes.LoadRuntimeInfoAction,
  props<{ spaceId?: number }>()
);
