import { Injectable } from '@angular/core';

import * as auth0 from 'auth0-js';
import { Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { env_auth0, environment } from '@spaceti/environments';
import { LocationService, getPermission } from '@spaceti/core/common';
import {
  AccountSubscription,
  Envelope,
  IAccessToken,
  IAccount,
  Pack,
  RuntimeInfo,
  UserRoleModel,
} from '@spaceti/models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private auth0 = new auth0.WebAuth(env_auth0);
  private jwt = new JwtHelperService();

  private AUTH_TOKEN_KEY = 'auth_token';
  private USER_LANG = 'user-lang';

  private decodedAccessToken: IAccessToken;
  private userRole: string;

  constructor(private locationService: LocationService, private http: HttpClient) {}

  /** Soft validation of access token */
  login(accessToken: string): boolean {
    this.decodedAccessToken = this.decodeToken(accessToken);
    this.userRole = this.getUserRole(this.decodedAccessToken);
    /** Check expiration else try to renew token */
    if (this.decodedAccessToken.exp < ~~(Date.now() / 1000)) {
      this.removeToken();
      return false;
    }

    this.setToken(accessToken);
    return true;
  }

  /** Load user data */
  userInfo(accessToken: string): Observable<Pack<any>> {
    return new Observable((observer) => {
      this.auth0.client.userInfo(accessToken, (err, data) => {
        if (data) {
          observer.next({
            success: true,
            data: { ...data, accessToken: accessToken },
          });
        } else if (err) {
          observer.next({ success: false, data: null, error: err });
        }
        observer.complete();
      });
    });
  }

  /** Parse user role from token permission for current organization or logout user without permission */
  getUserRole(token: IAccessToken): string {
    return getPermission(token, this.getOrganization());
  }

  decodeToken(accessToken: string): IAccessToken {
    return this.jwt.decodeToken<IAccessToken>(accessToken);
  }

  setToken(accessToken: string): void {
    localStorage.setItem(this.AUTH_TOKEN_KEY, accessToken);
  }

  getToken(): string {
    return localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  getUserLanguage(): string {
    return localStorage.getItem(this.USER_LANG) ?? 'en';
  }

  isSignedIn(): boolean {
    if (!localStorage.getItem(this.AUTH_TOKEN_KEY)) return false;
    try {
      this.decodedAccessToken = this.decodeToken(this.getToken());
      if (this.decodedAccessToken.exp < ~~(Date.now() / 1000)) {
        this.removeToken();
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  hasIncludedTenant(tenant: Array<string>): boolean {
    if (Array.isArray(tenant) && tenant.length)
      if (tenant.some((item) => item === this.getOrganization())) return true;
    return false;
  }

  hasExcludedTenant(tenant: Array<string>): boolean {
    if (Array.isArray(tenant) && tenant.length)
      if (tenant.some((item) => item === this.getOrganization())) return true;
    return false;
  }

  getOrganization(): string {
    return this.locationService.getOrganization();
  }

  removeToken(): void {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
  }

  redirectToLogin(): void {
    this.locationService.redirectToLogin();
  }

  logout(errorCode?: number): void {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    this.locationService.redirectToLogout();
  }

  fetchRuntimeInfo(): Observable<Envelope<RuntimeInfo>> {
    const url = `${environment.scheme}${this.getOrganization()}.${
      environment.host
    }/${'api/v1/users/current'}`;
    return this.http.get(url).pipe(
      map(
        (resp: RuntimeInfo) =>
          <Envelope<RuntimeInfo>>{
            success: true,
            data: resp,
          },
        catchError((error) => {
          return of({
            success: false,
            data: null,
            resultCode: error.status,
            error: '',
          } as Envelope<any>);
        })
      )
    );
  }

  /** Get roles */
  loadRoles(): Observable<Envelope<[UserRoleModel]>> {
    const url = `${environment.scheme}${this.getOrganization()}.${
      environment.host
    }/${'/api/roles'}`;
    return this.http.get(url).pipe(
      map(
        (x: { results: [UserRoleModel] }) =>
          <Envelope<[UserRoleModel]>>{
            success: true,
            data: x.results,
          }
      ),
      catchError((error) => {
        return of({
          success: false,
          data: null,
          resultCode: error.status,
          error: '',
        } as Envelope<any>);
      })
    );
  }

  /** Get account */
  loadAccount(): Observable<Envelope<IAccount>> {
    const url = `${environment.scheme}${this.getOrganization()}.${
      environment.host
    }/${'api/v1/structure/account'}`;
    return this.http.get(url).pipe(
      map(
        (x: IAccount) =>
          <Envelope<IAccount>>{
            success: true,
            data: x,
          }
      ),
      catchError((error) => {
        return of({
          success: false,
          data: null,
          resultCode: error.status,
          error: '',
        } as Envelope<any>);
      })
    );
  }

  /** Get account subscription */
  loadAccountSubscription(account_id: number): Observable<Envelope<AccountSubscription>> {
    const url = `${environment.scheme}${this.getOrganization()}.${
      environment.host
    }/${'api/subscriptions/accounts/'}${account_id}`;
    return this.http.get(url).pipe(
      timeout(2000),
      map(
        (x: AccountSubscription) =>
          <Envelope<AccountSubscription>>{
            success: true,
            data: x,
          }
      ),
      catchError((error) => {
        return of({
          success: false,
          data: null,
          resultCode: error.status,
          error: '',
        } as Envelope<any>);
      })
    );
  }

  /** Get analytics info */
  loadAnalyticsInfo(): Observable<Envelope<any>> {
    const url = `${environment.scheme}${environment.public_api}/${'analytics/dashboard/menu'}`;
    return this.http.get(url).pipe(
      map(
        (x: IAccount) =>
          <Envelope<IAccount>>{
            success: true,
            data: x,
          }
      ),
      catchError((error) => {
        return of({
          success: false,
          data: null,
          resultCode: error.status,
          error: '',
        } as Envelope<any>);
      })
    );
  }
}
