export const LockersFilters = [
  {
    id: 1,
    param_name: 'all',
    param_id: 1,
    title: 'global-filter-all',
    active: false,
    group: null,
  },
  {
    id: 2,
    param_name: 'assigned',
    param_id: 1,
    title: 'lockers-filter-assigned',
    active: false,
    group: 'assign',
  },
  {
    id: 3,
    param_name: 'unassigned',
    param_id: 1,
    title: 'lockers-filter-unassigned',
    active: false,
    group: 'assign',
  },
  {
    id: 4,
    param_name: 'locker_group_id',
    param_id: null,
    title: '',
    active: false,
    group: 'locker_group',
  },
  {
    id: 5,
    param_name: 'locker_group_id',
    param_id: null,
    title: '',
    active: false,
    group: 'locker_group',
  },
  {
    id: 6,
    param_name: 'enabled',
    param_id: 1,
    title: 'lockers-filters-enabled',
    active: false,
    group: 'enable',
  },
  {
    id: 7,
    param_name: 'disabled',
    param_id: 1,
    title: 'lockers-filters-disabled',
    active: false,
    group: 'enable',
  },
];
