export const SpaceTypes = [
  {
    title: 'Common Spaces',
    titleLocalized: 'left-menu-common-spaces',
  },
  {
    title: 'Meeting Rooms',
    titleLocalized: 'left-menu-meeting-rooms',
  },
  {
    title: 'Other',
    titleLocalized: 'support-form-select-value-other',
  },
  {
    title: 'Parking Spaces',
    titleLocalized: 'left-menu-parking-spaces',
  },
  {
    title: 'Workstations',
    titleLocalized: 'left-menu-workstations',
  },
];
