import {
  LoadAnalyticsInfoAction,
  LoadAnalyticsInfoFailedAction,
  LoadAnalyticsInfoSuccessfulAction,
} from './../actions';
import { createReducer, on } from '@ngrx/store';
import { AnalyticsInfo, Pack } from '@spaceti/models';

export const initialState: Pack<AnalyticsInfo> = {
  loading: false,
  data: null,
};

const reducerFce = createReducer(
  initialState,
  on(LoadAnalyticsInfoAction, (state) => {
    return { ...state, loading: true };
  }),

  on(LoadAnalyticsInfoSuccessfulAction, (state, action) => ({
    ...state,
    loading: false,
    data: action.analytics,
  })),

  on(LoadAnalyticsInfoFailedAction, (state) => {
    return { ...state, loading: false };
  })
);
export function analyticsReducer(state, action) {
  return reducerFce(state, action);
}
