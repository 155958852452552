import { CommonModule } from '@angular/common';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as effects from './effects';
import { reducers } from './reducers';
import * as guards from './guards';

export const EFFECTS = [effects.InitialEffects];

export const IMPORTS = [
  CommonModule,
  EffectsModule.forRoot(EFFECTS),
  StoreModule.forRoot(reducers, {
    runtimeChecks: {
      /** TODO: remove */
      strictStateImmutability: false,
      strictActionImmutability: false,
    },
  }),
  StoreRouterConnectingModule.forRoot(),
  /** TO BE removed in production build */
  StoreDevtoolsModule.instrument({
    maxAge: 50,
  }),
];

export const GUARDS = [guards.LoginGuard, guards.QRLoginGuard];

export const EXPORTS = [CommonModule];
