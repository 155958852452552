export const Intervals = [
  {
    id: 7,
    name: '8 Hours (9 - 17)',
    value: 7,
    from: '09:00',
    to: '17:00',
    work_day_query: 'wrk9wday',
    day_query: 'wrk9day',
    month_query: 'wrk9month',
    day_report_type: 2,
    day_aggregation: 4,
    week_report_type: 8,
    week_aggregation: 14,
    month_report_type: 5,
    month_aggregation: 14,
    quarter_report_type: 6,
    quarter_aggregation: 15,
    year_report_type: 7,
    year_aggregation: 15,
    start: -1,
    end: 10,
    length_of_data: 9,
    number_of_days: 5,
  },
  {
    id: 8,
    name: '12 Hours (7-19)',
    value: 8,
    from: '07:00',
    to: '19:00',
    work_day_query: 'wrk7wday',
    day_query: 'wrk7day',
    month_query: 'wrk7month',
    day_report_type: 9,
    day_aggregation: 4,
    week_report_type: 8,
    week_aggregation: 12,
    month_report_type: 5,
    month_aggregation: 10,
    quarter_report_type: 6,
    quarter_aggregation: 13,
    year_report_type: 7,
    year_aggregation: 13,
    start: -1,
    end: 14,
    length_of_data: 13,
    number_of_days: 5,
  },
  {
    id: 9,
    name: '24 Hours',
    value: 9,
    from: '00:01',
    to: '23:59',
    work_day_query: 'wday',
    day_query: 'day',
    month_query: 'month',
    day_report_type: 3,
    day_aggregation: 5,
    week_report_type: 4,
    week_aggregation: 8,
    month_report_type: 5,
    month_aggregation: 8,
    quarter_report_type: 6,
    quarter_aggregation: 9,
    year_report_type: 7,
    year_aggregation: 9,
    start: -1,
    end: 25,
    length_of_data: 24,
    number_of_days: 7,
  },
];

export const IntervalTypes = [
  { id: 1, name: '8 Hours', value: 1 },
  { id: 2, name: '12 Hours', value: 2 },
  { id: 3, name: '24 Hours', value: 3 },
  { id: 4, name: '15 mins', value: 4 },
  { id: 5, name: '30 mins', value: 5 },
  { id: 6, name: '1 hour', value: 6 },
  {
    id: 7,
    name: '8 Hours (9 - 17)',
    value: 7,
    work_day_query: 'wrk9wday',
    day_query: 'wrk9day',
    month_query: 'wrk9month',
    day_report_type: 2,
    day_aggregation: 4,
    week_report_type: 8,
    week_aggregation: 14,
    month_report_type: 5,
    month_aggregation: 14,
    quarter_report_type: 6,
    quarter_aggregation: 15,
    year_report_type: 7,
    year_aggregation: 15,
    start: -1,
    end: 10,
    length_of_data: 9,
    number_of_days: 5,
  },
  {
    id: 8,
    name: '12 Hours (7-19)',
    value: 8,
    work_day_query: 'wrk7wday',
    day_query: 'wrk7day',
    month_query: 'wrk7month',
    day_report_type: 9,
    day_aggregation: 4,
    week_report_type: 8,
    week_aggregation: 12,
    month_report_type: 5,
    month_aggregation: 10,
    quarter_report_type: 6,
    quarter_aggregation: 13,
    year_report_type: 7,
    year_aggregation: 13,
    start: -1,
    end: 14,
    length_of_data: 13,
    number_of_days: 5,
  },
  {
    id: 9,
    name: '24 Hours',
    value: 9,
    work_day_query: 'wday',
    day_query: 'day',
    month_query: 'month',
    day_report_type: 3,
    day_aggregation: 5,
    week_report_type: 4,
    week_aggregation: 8,
    month_report_type: 5,
    month_aggregation: 8,
    quarter_report_type: 6,
    quarter_aggregation: 9,
    year_report_type: 7,
    year_aggregation: 9,
    start: -1,
    end: 25,
    length_of_data: 24,
    number_of_days: 7,
  },
];

export const DayIntervalTypes = [
  {
    ['09h']: [65, 30],
  },
  {
    ['10h']: [135, 30],
  },
  {
    ['11h']: [205, 30],
  },
  {
    ['12h']: [275, 30],
  },
  {
    ['13h']: [345, 30],
  },
  {
    ['14h']: [415, 30],
  },
  {
    ['15h']: [485, 30],
  },
  {
    ['16h']: [555, 30],
  },
  {
    ['17h']: [625, 30],
  },
];

export const HalfDayIntervalTYpes = [
  {
    ['07h']: [65, 30],
  },
  {
    ['08h']: [135, 30],
  },
  {
    ['09h']: [205, 30],
  },
  {
    ['10h']: [275, 30],
  },
  {
    ['11h']: [345, 30],
  },
  {
    ['12h']: [415, 30],
  },
  {
    ['13h']: [485, 30],
  },
  {
    ['14h']: [555, 30],
  },
  {
    ['15h']: [625, 30],
  },
  {
    ['16h']: [695, 30],
  },
  {
    ['17h']: [765, 30],
  },
  {
    ['18h']: [835, 30],
  },
  {
    ['19h']: [905, 30],
  },
];

export const AllHalfDayIntervalTYpes = [
  {
    ['00h']: [65, 30],
  },
  {
    ['01h']: [135, 30],
  },
  {
    ['02h']: [205, 30],
  },
  {
    ['03h']: [275, 30],
  },
  {
    ['04h']: [345, 30],
  },
  {
    ['05h']: [415, 30],
  },
  {
    ['06h']: [485, 30],
  },
  {
    ['07h']: [555, 30],
  },
  {
    ['08h']: [625, 30],
  },
  {
    ['09h']: [695, 30],
  },
  {
    ['10h']: [765, 30],
  },
  {
    ['11h']: [835, 30],
  },
  {
    ['12h']: [905, 30],
  },
  {
    ['13h']: [975, 30],
  },
  {
    ['14h']: [1045, 30],
  },
  {
    ['15h']: [1115, 30],
  },
  {
    ['16h']: [1185, 30],
  },
  {
    ['17h']: [1255, 30],
  },
  {
    ['18h']: [1325, 30],
  },
  {
    ['19h']: [1395, 30],
  },
  {
    ['20h']: [1465, 30],
  },
  {
    ['21h']: [1535, 30],
  },
  {
    ['22h']: [1605, 30],
  },
  {
    ['23h']: [1675, 30],
  },
];

export const QuarterIntervalTYpes = [
  {
    ['Mondays']: [50, 30],
  },
  {
    ['Tuesdays']: [120, 30],
  },
  {
    ['Wednesdays']: [185, 30],
  },
  {
    ['Thursdays']: [260, 30],
  },
  {
    ['Fridays']: [330, 30],
  },
];

export const QuarterAllIntervalTYpes = [
  {
    ['Mondays']: [50, 30],
  },
  {
    ['Tuesdays']: [120, 30],
  },
  {
    ['Wednesdays']: [185, 30],
  },
  {
    ['Thursdays']: [260, 30],
  },
  {
    ['Fridays']: [330, 30],
  },
  {
    ['Saturdays']: [400, 30],
  },
  {
    ['Sundays']: [470, 30],
  },
];

export const YearIntervalTypes = [
  {
    ['Mondays']: [50, 30],
  },
  {
    ['Tuesdays']: [120, 30],
  },
  {
    ['Wednesdays']: [185, 30],
  },
  {
    ['Thursdays']: [260, 30],
  },
  {
    ['Fridays']: [330, 30],
  },
];

export const YearAllIntervalTypes = [
  {
    ['Mondays']: [50, 30],
  },
  {
    ['Tuesdays']: [120, 30],
  },
  {
    ['Wednesdays']: [185, 30],
  },
  {
    ['Thursdays']: [260, 30],
  },
  {
    ['Fridays']: [330, 30],
  },
  {
    ['Saturdays']: [400, 30],
  },
  {
    ['Sundays']: [470, 30],
  },
];
