import { createAction, props } from '@ngrx/store';
import { UserRoleModel } from '@spaceti/models';
import { type } from '@spaceti/utils';

export const UserRolesActionTypes = {
  SetUserRolesAction: type('[Effect] Set user roles'),
};

export const SetUserRolesAction = createAction(
  UserRolesActionTypes.SetUserRolesAction,
  props<{ roles: Array<UserRoleModel> }>()
);
