import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromNow',
})
@Injectable()
export class TimeFromNowPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * @param date date in a string format
   */
  transform(date: string): any {
    return moment(date)
      .locale(this.translateService.currentLang)
      .startOf('minutes')
      .fromNow();
  }
}
