import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'dateLocale',
  pure: true,
})
export class DateLocalePipe implements PipeTransform {

  transform(value: number | string | Date, format: string): unknown {
    return moment(value).locale(localStorage.getItem('user-lang') || 'en').format(format);
  }

}
