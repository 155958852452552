import { SetUserRolesAction } from './../actions';
import { createReducer, on } from '@ngrx/store';
import { UserRoleModel } from '@spaceti/models';

export const initialState: Array<UserRoleModel> = [];

const reducerFce = createReducer(
  initialState,
  on(SetUserRolesAction, (state, action) => {
    return action.roles;
  })
);
export function userRolesReducer(state, action) {
  return reducerFce(state, action);
}
