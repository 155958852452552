import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  SearchPipe,
  TimeFromNowPipe,
  SortArrayPipe,
  IntersectionObserverDirective,
  DateLocalePipe
} from './utils';

@NgModule({
  declarations: [
    SearchPipe,
    TimeFromNowPipe,
    SortArrayPipe,
    IntersectionObserverDirective,
    DateLocalePipe,
  ],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [
    SearchPipe,
    TimeFromNowPipe,
    SortArrayPipe,
    IntersectionObserverDirective,
    DateLocalePipe,
  ],
})
export class UtilsModule {}
