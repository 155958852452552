import { Routes } from '@angular/router';
import { LoginGuard } from '@spaceti/core/initial';

export const routes: Routes = [
  {
    path: '',
    canLoad: [LoginGuard],
    loadChildren: () =>
      import('@spaceti/features/main').then((m) => m.DashboardMainModule),
  },
];
