import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

import { AccountService } from './account.service';
import { environment } from '@spaceti/environments';

/**
 * Sentry error tracking lib config and install
 */
import * as Sentry from '@sentry/browser';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { getRuntime } from '../selectors';

@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private accountService: AccountService,
    private router: Router,
    private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.pipe(
      select(getRuntime),
      first(),
      mergeMap((runtime) => next.handle(this.includeToken(request, runtime?.id))),
      map((x) => x),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          /** Sentry init */
          const isLoginRequest = request.url.endsWith('/login/password');
          if (environment.sentry_token && !isLoginRequest && environment.production) {
            Sentry.init({
              release: environment.release !== 'GITHUB_RELEASE_HASH' ? environment.release : null,
              environment: 'production',
              dsn: environment.sentry_token,
            });
            const eventId = Sentry.captureException(error);
            // Sentry.showReportDialog({ eventId });
          }

          switch ((error as HttpErrorResponse).status) {
            case 401:
              return this.catch401(request, next);
            default:
              return throwError(() => error);
          }
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  catch401(request: HttpRequest<any>, next: HttpHandler) {
    const isLoginRequest = request.url.endsWith('/login/password');
    const isConfirmRequest = request.url.includes('user/confirm');
    const isInitalRequest =
      request.url.includes('api/v1/users/current') ||
      request.url.includes('/api/roles') ||
      request.url.includes('/v1/structure/account');
    if (isLoginRequest || isConfirmRequest || isInitalRequest) {
      return next.handle(request);
    }
    this.accountService.removeToken();
    this.accountService.redirectToLogin();
    return throwError('Cannot refresh token');
  }

  private includeToken(request: HttpRequest<any>, userId?: number): HttpRequest<any> {
    const configPath = request.url.startsWith(location.origin + '/config.json');
    const i18nPath = request.url.startsWith(location.origin + '/assets/i18n/');

    if (!configPath && !i18nPath) {
      const dashboardWhitelist = environment.whitelisted_domains;
      const publicAPI =
        request.url.includes(environment.public_api) || request.url.includes('/ai/chat');

      if (dashboardWhitelist.some((domain) => request.url.includes(domain))) {
        let editedHeaders = request.headers;

        editedHeaders = editedHeaders.set('Platform', 'Web');

        if (this.accountService.getOrganization()) {
          editedHeaders = editedHeaders.set('Account', this.accountService.getOrganization());
        }

        if (userId) {
          editedHeaders = editedHeaders.set('User-ID', userId.toString());
        }

        if (this.router.url) {
          editedHeaders = editedHeaders.set(
            'Trace-ID',
            this.router.url + ':' + new Date().getTime()
          );
        }

        /** TODO: Create global Store for the whole application with token, bildings etc. */
        editedHeaders = editedHeaders.set(
          'Authorization',
          (!publicAPI ? 'Bearer ' : '') + this.accountService.getToken()
        );

        editedHeaders = editedHeaders.set(
          'Accept-Language',
          this.accountService.getUserLanguage().toString().toLowerCase()
        );

        return request.clone({ headers: editedHeaders });
      }

      // staging public API requires a different token and even in production there has to be no scheme, just plain JWT (in case of per-tenant API access which so far is the case)
      if (publicAPI) {
        let editedHeaders = request.headers;
        editedHeaders = editedHeaders.set('Authorization', this.accountService.getToken());
        return request.clone({ headers: editedHeaders });
      }
    }
    return request;
  }
}
