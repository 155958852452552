export const BeaconTypes = [
  {
    text: 'Smart Stones',
    value: 1,
    selected: true,
  },
  {
    text: 'Chairs',
    value: 2,
    selected: true,
  },
  {
    text: 'CO2 sensors',
    value: 4,
    selected: true,
  },
  {
    text: 'Parking Sensors',
    value: 5,
    selected: true,
  },
  {
    text: 'Presence smart stones',
    value: 6,
    selected: true,
  },
];
