import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortArray',
})
export class SortArrayPipe implements PipeTransform {
  /**
   *
   * @param array array of items or objects to filter
   * @param order order of sorting, 1 for ascending, -1 for descending, null for not sorting
   * @param field parameter string of objects in array
   *
   */
  transform(array: any, order: number, field?: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    if (!order) {
      return array;
    }
    array.sort((a: any, b: any) => {
      const first = field ? a[field] : a;
      const second = field ? b[field] : b;

      if (typeof first === 'string') {
        return first.localeCompare(second) * order;
      } else if (Array.isArray(first)) {
        return (first.length - second.length) * order;
      } else {
        return (first - second) * order;
      }
    });
    return array;
  }
}
