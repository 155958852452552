import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fromIntersectionObserver, IntersectionStatus } from './from-intersection-observer';

@Directive({
  selector: '[intersectionObserver]',
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {
  @Input() intersectionDebounce = 0;
  @Input() intersectionRootMargin = '0px';
  @Input() intersectionRoot: HTMLElement;
  @Input() intersectionThreshold: number | number[] = 0.2;

  @Output() visibilityChange = new EventEmitter<boolean>();

  private destroy$ = new Subject<void>();

  constructor(private element: ElementRef) {}
  ngOnInit() {
    const element = this.element.nativeElement;

    const config = {
      root: this.intersectionRoot,
      rootMargin: this.intersectionRootMargin,
      threshold: this.intersectionThreshold,
    };
    fromIntersectionObserver(element, config)
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.visibilityChange.emit(status === IntersectionStatus.Visible);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
