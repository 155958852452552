export const Countries = {
  au: 'Australia',
  ca: 'Canada',
  cz: 'Czech Republic',
  fr: 'France',
  de: 'Germany',
  it: 'Italy',
  ie: 'Ireland',
  jp: 'Japan',
  nl: 'Netherlands',
  no: 'Norway',
  pl: 'Poland',
  sk: 'Slovakia',
  es: 'Spain',
  se: 'Sweden',
  ch: 'Switzerland',
  uk: 'United Kingdom',
  us: 'United States of America',
  other: 'Other',
};
