export const diacriticsMap = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  N: 'N',
  O: 'O',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  U: 'U',
  V: 'V',
  W: 'W',
  X: 'X',
  Y: 'Y',
  Z: 'Z',
  a: 'a',
  b: 'b',
  c: 'c',
  d: 'd',
  e: 'e',
  f: 'f',
  g: 'g',
  h: 'h',
  i: 'i',
  j: 'j',
  k: 'k',
  l: 'l',
  m: 'm',
  n: 'n',
  o: 'o',
  p: 'p',
  q: 'q',
  r: 'r',
  s: 's',
  t: 't',
  u: 'u',
  v: 'v',
  w: 'w',
  x: 'x',
  y: 'y',
  z: 'z',
  À: 'A',
  Á: 'A',
  Â: 'A',
  Ã: 'A',
  Ä: 'A',
  Å: 'A',
  Æ: 'AE',
  Ç: 'C',
  È: 'E',
  É: 'E',
  Ê: 'E',
  Ë: 'E',
  Ì: 'I',
  Í: 'I',
  Î: 'I',
  Ï: 'I',
  Ñ: 'N',
  Ò: 'O',
  Ó: 'O',
  Ô: 'O',
  Õ: 'O',
  Ö: 'O',
  Ø: 'O',
  Ù: 'U',
  Ú: 'U',
  Û: 'U',
  Ü: 'U',
  Ý: 'Y',
  ß: 's',
  à: 'a',
  á: 'a',
  â: 'a',
  ã: 'a',
  ä: 'a',
  å: 'a',
  æ: 'ae',
  ç: 'c',
  è: 'e',
  é: 'e',
  ê: 'e',
  ë: 'e',
  ì: 'i',
  í: 'i',
  î: 'i',
  ï: 'i',
  ñ: 'n',
  ò: 'o',
  ó: 'o',
  ô: 'o',
  õ: 'o',
  ö: 'o',
  ø: 'o',
  ù: 'u',
  ú: 'u',
  û: 'u',
  ü: 'u',
  ý: 'y',
  ÿ: 'y',
  Ā: 'A',
  ā: 'a',
  Ă: 'A',
  ă: 'a',
  Ą: 'A',
  ą: 'a',
  Ć: 'C',
  ć: 'c',
  Ĉ: 'C',
  ĉ: 'c',
  Ċ: 'C',
  ċ: 'c',
  Č: 'C',
  č: 'c',
  Ď: 'D',
  ď: 'd',
  Đ: 'D',
  đ: 'd',
  Ē: 'E',
  ē: 'e',
  Ĕ: 'E',
  ĕ: 'e',
  Ė: 'E',
  ė: 'e',
  Ę: 'E',
  ę: 'e',
  Ě: 'E',
  ě: 'e',
  Ĝ: 'G',
  ĝ: 'g',
  Ğ: 'G',
  ğ: 'g',
  Ġ: 'G',
  ġ: 'g',
  Ģ: 'G',
  ģ: 'g',
  Ĥ: 'H',
  ĥ: 'h',
  Ħ: 'H',
  ħ: 'h',
  Ĩ: 'I',
  ĩ: 'i',
  Ī: 'I',
  ī: 'i',
  Ĭ: 'I',
  ĭ: 'i',
  Į: 'I',
  į: 'i',
  İ: 'I',
  ı: 'i',
  Ĵ: 'J',
  ĵ: 'j',
  Ķ: 'K',
  ķ: 'k',
  Ĺ: 'L',
  ĺ: 'l',
  Ļ: 'L',
  ļ: 'l',
  Ľ: 'L',
  ľ: 'l',
  Ŀ: 'L',
  ŀ: 'l',
  Ł: 'L',
  ł: 'l',
  Ń: 'N',
  ń: 'n',
  Ņ: 'N',
  ņ: 'n',
  Ň: 'N',
  ň: 'n',
  ŉ: 'n',
  Ō: 'O',
  ō: 'o',
  Ŏ: 'O',
  ŏ: 'o',
  Ő: 'O',
  ő: 'o',
  Ŕ: 'R',
  ŕ: 'r',
  Ŗ: 'R',
  ŗ: 'r',
  Ř: 'R',
  ř: 'r',
  Ś: 'S',
  ś: 's',
  Ŝ: 'S',
  ŝ: 's',
  Ş: 'S',
  ş: 's',
  Š: 'S',
  š: 's',
  Ţ: 'T',
  ţ: 't',
  Ť: 'T',
  ť: 't',
  Ŧ: 'T',
  ŧ: 't',
  Ũ: 'U',
  ũ: 'u',
  Ū: 'U',
  ū: 'u',
  Ŭ: 'U',
  ŭ: 'u',
  Ů: 'U',
  ů: 'u',
  Ű: 'U',
  ű: 'u',
  Ų: 'U',
  ų: 'u',
  Ŵ: 'W',
  ŵ: 'w',
  Ŷ: 'Y',
  ŷ: 'y',
  Ÿ: 'Y',
  Ź: 'Z',
  ź: 'z',
  Ż: 'Z',
  ż: 'z',
  Ž: 'Z',
  ž: 'z',
  ſ: 'l',
  ƀ: 'b',
  Ɓ: 'B',
  Ƃ: 'B',
  ƃ: 'b',
  Ɔ: 'O',
  Ƈ: 'C',
  ƈ: 'c',
  Ɖ: 'D',
  Ɗ: 'D',
  Ƌ: 'D',
  ƌ: 'd',
  Ǝ: 'E',
  Ɛ: 'E',
  Ƒ: 'F',
  ƒ: 'f',
  Ɠ: 'G',
  ƕ: 'hv',
  Ɨ: 'I',
  Ƙ: 'K',
  ƙ: 'k',
  ƚ: 'l',
  Ɯ: 'M',
  Ɲ: 'N',
  ƞ: 'n',
  Ɵ: 'O',
  Ơ: 'O',
  ơ: 'o',
  Ƣ: 'OI',
  ƣ: 'oi',
  Ƥ: 'P',
  ƥ: 'p',
  Ƭ: 'T',
  ƭ: 't',
  Ʈ: 'T',
  Ư: 'U',
  ư: 'u',
  Ʋ: 'V',
  Ƴ: 'Y',
  ƴ: 'y',
  Ƶ: 'Z',
  ƶ: 'z',
  Ǆ: 'DZ',
  ǅ: 'Dz',
  ǆ: 'dz',
  Ǉ: 'LJ',
  ǈ: 'Lj',
  ǉ: 'lj',
  Ǌ: 'NJ',
  ǋ: 'Nj',
  ǌ: 'nj',
  Ǎ: 'A',
  ǎ: 'a',
  Ǐ: 'I',
  ǐ: 'i',
  Ǒ: 'O',
  ǒ: 'o',
  Ǔ: 'U',
  ǔ: 'u',
  Ǖ: 'U',
  ǖ: 'u',
  Ǘ: 'U',
  ǘ: 'u',
  Ǚ: 'U',
  ǚ: 'u',
  Ǜ: 'U',
  ǜ: 'u',
  ǝ: 'e',
  Ǟ: 'A',
  ǟ: 'a',
  Ǡ: 'A',
  ǡ: 'a',
  Ǣ: 'AE',
  ǣ: 'ae',
  Ǥ: 'G',
  ǥ: 'g',
  Ǧ: 'G',
  ǧ: 'g',
  Ǩ: 'K',
  ǩ: 'k',
  Ǫ: 'O',
  ǫ: 'o',
  Ǭ: 'O',
  ǭ: 'o',
  ǰ: 'j',
  Ǳ: 'DZ',
  ǲ: 'Dz',
  ǳ: 'dz',
  Ǵ: 'G',
  ǵ: 'g',
  Ǹ: 'N',
  ǹ: 'n',
  Ǻ: 'A',
  ǻ: 'a',
  Ǽ: 'AE',
  ǽ: 'ae',
  Ǿ: 'O',
  ǿ: 'o',
  Ȁ: 'A',
  ȁ: 'a',
  Ȃ: 'A',
  ȃ: 'a',
  Ȅ: 'E',
  ȅ: 'e',
  Ȇ: 'E',
  ȇ: 'e',
  Ȉ: 'I',
  ȉ: 'i',
  Ȋ: 'I',
  ȋ: 'i',
  Ȍ: 'O',
  ȍ: 'o',
  Ȏ: 'O',
  ȏ: 'o',
  Ȑ: 'R',
  ȑ: 'r',
  Ȓ: 'R',
  ȓ: 'r',
  Ȕ: 'U',
  ȕ: 'u',
  Ȗ: 'U',
  ȗ: 'u',
  Ș: 'S',
  ș: 's',
  Ț: 'T',
  ț: 't',
  Ȟ: 'H',
  ȟ: 'h',
  Ƞ: 'N',
  Ȣ: 'OU',
  ȣ: 'ou',
  Ȥ: 'Z',
  ȥ: 'z',
  Ȧ: 'A',
  ȧ: 'a',
  Ȩ: 'E',
  ȩ: 'e',
  Ȫ: 'O',
  ȫ: 'o',
  Ȭ: 'O',
  ȭ: 'o',
  Ȯ: 'O',
  ȯ: 'o',
  Ȱ: 'O',
  ȱ: 'o',
  Ȳ: 'Y',
  ȳ: 'y',
  Ⱥ: 'A',
  Ȼ: 'C',
  ȼ: 'c',
  Ƚ: 'L',
  Ⱦ: 'T',
  ȿ: 's',
  ɀ: 'z',
  ɐ: 'a',
  ɓ: 'b',
  ɔ: 'o',
  ɖ: 'd',
  ɗ: 'd',
  ɛ: 'e',
  ɠ: 'g',
  ɥ: 'h',
  ɨ: 'i',
  ɫ: 'l',
  ɯ: 'm',
  ɱ: 'm',
  ɲ: 'n',
  ɵ: 'o',
  ɽ: 'r',
  ʈ: 't',
  ʉ: 'u',
  ʋ: 'v',
  ʌ: 'v',
  ᵹ: 'g',
  ᵽ: 'p',
  Ḁ: 'A',
  ḁ: 'a',
  Ḃ: 'B',
  ḃ: 'b',
  Ḅ: 'B',
  ḅ: 'b',
  Ḇ: 'B',
  ḇ: 'b',
  Ḉ: 'C',
  ḉ: 'c',
  Ḋ: 'D',
  ḋ: 'd',
  Ḍ: 'D',
  ḍ: 'd',
  Ḏ: 'D',
  ḏ: 'd',
  Ḑ: 'D',
  ḑ: 'd',
  Ḓ: 'D',
  ḓ: 'd',
  Ḕ: 'E',
  ḕ: 'e',
  Ḗ: 'E',
  ḗ: 'e',
  Ḙ: 'E',
  ḙ: 'e',
  Ḛ: 'E',
  ḛ: 'e',
  Ḝ: 'E',
  ḝ: 'e',
  Ḟ: 'F',
  ḟ: 'f',
  Ḡ: 'G',
  ḡ: 'g',
  Ḣ: 'H',
  ḣ: 'h',
  Ḥ: 'H',
  ḥ: 'h',
  Ḧ: 'H',
  ḧ: 'h',
  Ḩ: 'H',
  ḩ: 'h',
  Ḫ: 'H',
  ḫ: 'h',
  Ḭ: 'I',
  ḭ: 'i',
  Ḯ: 'I',
  ḯ: 'i',
  Ḱ: 'K',
  ḱ: 'k',
  Ḳ: 'K',
  ḳ: 'k',
  Ḵ: 'K',
  ḵ: 'k',
  Ḷ: 'L',
  ḷ: 'l',
  Ḹ: 'L',
  ḹ: 'l',
  Ḻ: 'L',
  ḻ: 'l',
  Ḽ: 'L',
  ḽ: 'l',
  Ḿ: 'M',
  ḿ: 'm',
  Ṁ: 'M',
  ṁ: 'm',
  Ṃ: 'M',
  ṃ: 'm',
  Ṅ: 'N',
  ṅ: 'n',
  Ṇ: 'N',
  ṇ: 'n',
  Ṉ: 'N',
  ṉ: 'n',
  Ṋ: 'N',
  ṋ: 'n',
  Ṍ: 'O',
  ṍ: 'o',
  Ṏ: 'O',
  ṏ: 'o',
  Ṑ: 'O',
  ṑ: 'o',
  Ṓ: 'O',
  ṓ: 'o',
  Ṕ: 'P',
  ṕ: 'p',
  Ṗ: 'P',
  ṗ: 'p',
  Ṙ: 'R',
  ṙ: 'r',
  Ṛ: 'R',
  ṛ: 'r',
  Ṝ: 'R',
  ṝ: 'r',
  Ṟ: 'R',
  ṟ: 'r',
  Ṡ: 'S',
  ṡ: 's',
  Ṣ: 'S',
  ṣ: 's',
  Ṥ: 'S',
  ṥ: 's',
  Ṧ: 'S',
  ṧ: 's',
  Ṩ: 'S',
  ṩ: 's',
  Ṫ: 'T',
  ṫ: 't',
  Ṭ: 'T',
  ṭ: 't',
  Ṯ: 'T',
  ṯ: 't',
  Ṱ: 'T',
  ṱ: 't',
  Ṳ: 'U',
  ṳ: 'u',
  Ṵ: 'U',
  ṵ: 'u',
  Ṷ: 'U',
  ṷ: 'u',
  Ṹ: 'U',
  ṹ: 'u',
  Ṻ: 'U',
  ṻ: 'u',
  Ṽ: 'V',
  ṽ: 'v',
  Ṿ: 'V',
  ṿ: 'v',
  Ẁ: 'W',
  ẁ: 'w',
  Ẃ: 'W',
  ẃ: 'w',
  Ẅ: 'W',
  ẅ: 'w',
  Ẇ: 'W',
  ẇ: 'w',
  Ẉ: 'W',
  ẉ: 'w',
  Ẋ: 'X',
  ẋ: 'x',
  Ẍ: 'X',
  ẍ: 'x',
  Ẏ: 'Y',
  ẏ: 'y',
  Ẑ: 'Z',
  ẑ: 'z',
  Ẓ: 'Z',
  ẓ: 'z',
  Ẕ: 'Z',
  ẕ: 'z',
  ẖ: 'h',
  ẗ: 't',
  ẘ: 'w',
  ẙ: 'y',
  ẚ: 'a',
  ẛ: 's',
  ẞ: 'S',
  Ạ: 'A',
  ạ: 'a',
  Ả: 'A',
  ả: 'a',
  Ấ: 'A',
  ấ: 'a',
  Ầ: 'A',
  ầ: 'a',
  Ẩ: 'A',
  ẩ: 'a',
  Ẫ: 'A',
  ẫ: 'a',
  Ậ: 'A',
  ậ: 'a',
  Ắ: 'A',
  ắ: 'a',
  Ằ: 'A',
  ằ: 'a',
  Ẳ: 'A',
  ẳ: 'a',
  Ẵ: 'A',
  ẵ: 'a',
  Ặ: 'A',
  ặ: 'a',
  Ẹ: 'E',
  ẹ: 'e',
  Ẻ: 'E',
  ẻ: 'e',
  Ẽ: 'E',
  ẽ: 'e',
  Ế: 'E',
  ế: 'e',
  Ề: 'E',
  ề: 'e',
  Ể: 'E',
  ể: 'e',
  Ễ: 'E',
  ễ: 'e',
  Ệ: 'E',
  ệ: 'e',
  Ỉ: 'I',
  ỉ: 'i',
  Ị: 'I',
  ị: 'i',
  Ọ: 'O',
  ọ: 'o',
  Ỏ: 'O',
  ỏ: 'o',
  Ố: 'O',
  ố: 'o',
  Ồ: 'O',
  ồ: 'o',
  Ổ: 'O',
  ổ: 'o',
  Ỗ: 'O',
  ỗ: 'o',
  Ộ: 'O',
  ộ: 'o',
  Ớ: 'O',
  ớ: 'o',
  Ờ: 'O',
  ờ: 'o',
  Ở: 'O',
  ở: 'o',
  Ỡ: 'O',
  ỡ: 'o',
  Ợ: 'O',
  ợ: 'o',
  Ụ: 'U',
  ụ: 'u',
  Ủ: 'U',
  ủ: 'u',
  Ứ: 'U',
  ứ: 'u',
  Ừ: 'U',
  ừ: 'u',
  Ử: 'U',
  ử: 'u',
  Ữ: 'U',
  ữ: 'u',
  Ự: 'U',
  ự: 'u',
  Ỳ: 'Y',
  ỳ: 'y',
  Ỵ: 'Y',
  ỵ: 'y',
  Ỷ: 'Y',
  ỷ: 'y',
  Ỹ: 'Y',
  ỹ: 'y',
  Ａ: 'A',
  Ｂ: 'B',
  Ｃ: 'C',
  Ｄ: 'D',
  Ｅ: 'E',
  Ｆ: 'F',
  Ｇ: 'G',
  Ｈ: 'H',
  Ｉ: 'I',
  Ｊ: 'J',
  Ｋ: 'K',
  Ｌ: 'L',
  Ｍ: 'M',
  Ｎ: 'N',
  Ｏ: 'O',
  Ｐ: 'P',
  Ｑ: 'Q',
  Ｒ: 'R',
  Ｓ: 'S',
  Ｔ: 'T',
  Ｕ: 'U',
  Ｖ: 'V',
  Ｗ: 'W',
  Ｘ: 'X',
  Ｙ: 'Y',
  Ｚ: 'Z',
  ａ: 'a',
  ｂ: 'b',
  ｃ: 'c',
  ｄ: 'd',
  ｅ: 'e',
  ｆ: 'f',
  ｇ: 'g',
  ｈ: 'h',
  ｉ: 'i',
  ｊ: 'j',
  ｋ: 'k',
  ｌ: 'l',
  ｍ: 'm',
  ｎ: 'n',
  ｏ: 'o',
  ｐ: 'p',
  ｑ: 'q',
  ｒ: 'r',
  ｓ: 's',
  ｔ: 't',
  ｕ: 'u',
  ｖ: 'v',
  ｗ: 'w',
  ｘ: 'x',
  ｙ: 'y',
  ｚ: 'z',
};
