import { createAction, props } from '@ngrx/store';
import { AnalyticsInfo } from '@spaceti/models';
import { type } from '@spaceti/utils';

export const AnalyticsActionTypes = {
  LoadAnalyticsInfoAction: type('[Main] Load analytics info'),
  LoadAnalyticsInfoSuccessfulAction: type('[Main] Load analytics info successfull'),
  LoadAnalyticsInfoFailedAction: type('[Main] Load analytics info failed'),
};

export const LoadAnalyticsInfoAction = createAction(AnalyticsActionTypes.LoadAnalyticsInfoAction);

export const LoadAnalyticsInfoSuccessfulAction = createAction(
  AnalyticsActionTypes.LoadAnalyticsInfoSuccessfulAction,
  props<{ analytics: AnalyticsInfo }>()
);

export const LoadAnalyticsInfoFailedAction = createAction(
  AnalyticsActionTypes.LoadAnalyticsInfoFailedAction,
  props<{ error: any }>()
);
