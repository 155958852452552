export namespace mapStyles {
  export const layout = {
    text_allow_overlap: true,
    text_offset: [
      'interpolate',
      ['linear'],
      ['zoom'],
      19,
      ['literal', [0, 1]],
      20,
      ['literal', [0, 1]],
      21,
      ['literal', [0, 1.2]],
      22,
      ['literal', [0, 1.5]],
    ],
    text_offset_env: 1.5,
    text_size: {
      stops: [
        [20, 1],
        [21, 8],
        [22, 14],
      ],
      base: 2,
    },
    text_size_env: {
      stops: [
        [21, 13],
        [22, 15],
      ],
      base: 2,
    },
    icon_size: {
      stops: [
        [18, 0],
        [20, 0.4],
        [21, 0.6],
        [22, 0.8],
      ],
      base: 2,
    },
    
  };

  export const paint = {
    circle_radius: {
      stops: [
        [0, 0],
        [22, 200],
      ],
      base: 2,
    },
    icon_opacity: {
      stops: [
        [18, 0],
        [19, 1],
        [20, 1],
        [21, 1],
        [22, 1],
      ],
      base: 2,
    },
  };
}
