import * as moment from 'moment';

/** Get previous day */
export function getPreviousDate(): string {
  const date: any = moment(getSelectedDate(new Date()), 'YYYY/MM/DD').subtract(
    1,
    'days'
  );

  const yesterday = new Date(date._d);
  const previousYear = yesterday.getFullYear();
  const previousDay = yesterday.getDate();

  if (previousYear === 0 || previousDay === 0) {
    return getToday();
  } else {
    return previousYear + '/' + (yesterday.getMonth() + 1) + '/' + previousDay;
  }
}

/** Get date json */
export function getDateJson(): Date {
  return new Date(
    localStorage.getItem('selected_date')
      ? localStorage.getItem('selected_date')
      : getPreviousDate()
  );
}

/** Get date json for max range in timepicker */
export function getPreviousDateFormat(): Date {
  return new Date(getPreviousDate());
}

/**
 * Get selected date
 * @param date selected date in Date format
 * @returns selected date as string
 */
export function getSelectedDate(date: Date): string {
  return (
    date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
  );
}

/**
 * Set selected date
 * @returns selected date
 */
export function setSelectedDate(): string {
  return localStorage.getItem('selected_date')
    ? localStorage.getItem('selected_date')
    : getPreviousDate();
}

export function setSelectedFromDate(date): string | Date {
  return localStorage.getItem('selectedFromDate')
    ? localStorage.getItem('selectedFromDate')
    : getWeekStart(date);
}

export function setSelectedToDate(): string {
  return localStorage.getItem('selectedToDate')
    ? localStorage.getItem('selectedToDate')
    : getPreviousDate();
}

/**
 * Get week start
 * @param date selected date in Date format
 * @returns week start
 */
export function getWeekStart(date: Date): Date {
  const day = date.getDay() || 7;

  if (day !== 1) {
    date.setHours(-24 * (day - 1));
  }

  return date;
}

/**
 * Get month start
 * @param date selected date in Date format
 * @returns month start
 */
export function getMonthStart(date: Date): Date {
  const fullDate = new Date(date);

  return new Date(fullDate.getFullYear(), fullDate.getMonth(), 1);
}

/**
 * Get quarter start
 * @param date selected date in Date format
 * @returns quarter start
 */
export function getQuarterStart(date: Date): Date {
  return new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 1);
}

/**
 * Get quarter range
 * @param date selected date in Date format
 * @returns quarter range as string
 */
export function getQuarterRange(date: Date): string {
  return (
    getDateFormatAsTitle(
      moment(date, 'YYYY-MM')
        .startOf('quarter')
        .format()
    ) +
    ' - ' +
    getDateFormatAsTitle(
      moment(date, 'YYYY-MM')
        .endOf('quarter')
        .format()
    )
  );
}

/**
 * Get year format
 * @param date selected date in Date format
 * @returns year format as string
 */
export function getYearFormat(date: Date): string {
  return moment(date).format('YYYY');
}

/** Get today date */
export function getToday(): string {
  const date = new Date();

  return (
    date.getFullYear() +
    '/' +
    (date.getMonth() + 1) +
    '/' +
    (date.getDate() - 1)
  );
}

/** Setup date format for request */
export function setupDateFormat(date?: Date | any): string {
  if (date) return moment(date).format('YYYY-MM-DD');
  return moment().format('YYYY-MM-DD');
}

export function getDateForTitle(
  dateFrom: Date,
  dateTo: Date,
  isMultiple: boolean
): string {
  if (isMultiple) {
    return (
      parseFormatDate(moment(dateFrom)) + '-' + parseFormatDate(moment(dateTo))
    );
  } else {
    return parseFormatDate(dateFrom);
  }
}

/**
 * Get right format date as title
 * @param selectedDate selected date
 * @param is_multiple boolean value - check if is it multiple or not
 * @returns parsed right format date as title
 */
export function getDateFormatAsTitle(
  selectedDate: any,
  isMultiple?: boolean
): string {
  try {
    if (isMultiple) {
      return (
        parseFormatDate(moment(selectedDate).isoWeekday(1)) +
        '-' +
        parseFormatDate(moment(selectedDate).isoWeekday(7))
      );
    } else {
      return parseFormatDate(selectedDate);
    }
  } catch (err) {
    return selectedDate;
  }
}

/**
 * Parse date
 * @param date not parsed selected date as string or any
 * @returns parsed date
 */
export function parseFormatDate(date: string | any): string {
  const fullDate = new Date(date);

  return (
    fullDate.getDate() +
    '.' +
    (fullDate.getMonth() + 1) +
    '.' +
    fullDate.getFullYear()
  );
}

/**
 * Get days in month
 * @param date not parsed selected date as string or any
 * @returns parsed date
 */
export function getDaysInMonth(date: Date): number {
  return moment(date, 'YYYY-MM').daysInMonth();
}

/**
 * Check if date is current date or is ti in future
 * @param date selected date
 * @returns true or false
 */
export function dateIsInFuture(date: Date): boolean {
  if (moment(date).isSame(Date.now(), 'day') || new Date(date) > new Date())
    return true;

  return false;
}

/**
 * Check if date is valid date
 * @param date selected date
 * @returns true or false
 */
export function dateIsValid(date: Date): boolean {
  if (
    date instanceof Date &&
    !isNaN(date.getTime()) &&
    (new Date(date) > new Date('1/1/2016') &&
      new Date(date) < new Date('1/1/2100'))
  ) {
    return true;
  }

  return false;
}
