export interface IViewType {
  id: number;
  name: string;
  data: string;
  icon: string;
  type: string;
  graph: {
    minBottomValue?: number;
    minTopValue?: number;
    borderMin?: number;
    borderMax?: number;
    unit: string;
    edgeSpacing: number;
    roundValues: number;
    stepSize: number;
    heatmapColorGradient: Array<{ r: number; g: number; b: number }>;
    valueGradient?: Array<string>;
  };
}

export const WellbeingTypesData: IViewType[] = [
  {
    id: 0,
    name: 'analytics-table-environment-index',
    data: '- %',
    icon: 'wellbeing',
    type: 'well_being',
    graph: {
      minBottomValue: 0,
      minTopValue: 100,
      borderMin: 0,
      borderMax: 100,
      unit: '%',
      edgeSpacing: 20,
      roundValues: 10,
      stepSize: 25,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 1,
    name: 'analytics-tabs-categories-temperature',
    data: '- °C',
    icon: 'temperature',
    type: 'temp',
    graph: {
      minBottomValue: 22,
      minTopValue: 25,
      unit: '°C',
      edgeSpacing: 4,
      roundValues: 1,
      stepSize: 2,
      heatmapColorGradient: [
        { r: 84, g: 160, b: 255 },
        { r: 231, g: 223, b: 248 },
        { r: 255, g: 58, b: 38 },
      ],
    },
  },
  {
    id: 2,
    name: 'analytics-tabs-categories-humidity',
    data: '- %',
    icon: 'humidity',
    type: 'hum',
    graph: {
      minBottomValue: 0,
      minTopValue: 100,
      borderMin: 0,
      borderMax: 100,
      unit: '%',
      edgeSpacing: 10,
      roundValues: 10,
      stepSize: 25,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 3,
    name: 'analytics-tabs-categories-co2',
    data: '- ppm',
    icon: 'co2',
    type: 'co2',
    graph: {
      minBottomValue: 0,
      minTopValue: 600,
      borderMin: 0,
      unit: 'ppm',
      edgeSpacing: 100,
      roundValues: 100,
      stepSize: 200,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 4,
    name: 'stone-detail-pm25',
    data: '- µg/m3',
    icon: 'co2',
    type: 'pm25',
    graph: {
      minBottomValue: 0,
      minTopValue: 10,
      borderMin: 0,
      unit: 'µg/m3',
      edgeSpacing: 5,
      roundValues: 2.5,
      stepSize: 2.5,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 5,
    name: 'stone-detail-pm10',
    data: '- µg/m3',
    icon: 'co2',
    type: 'pm10',
    graph: {
      minBottomValue: 0,
      minTopValue: 100,
      borderMin: 0,
      unit: 'µg/m3',
      edgeSpacing: 10,
      roundValues: 10,
      stepSize: 20,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 6,
    name: 'stone-detail-tvoc',
    data: '- ppb',
    icon: 'co2',
    type: 'tvoc',
    graph: {
      minBottomValue: 0,
      minTopValue: 200,
      borderMin: 0,
      unit: 'ppb',
      edgeSpacing: 100,
      roundValues: 100,
      stepSize: 50,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 7,
    name: 'stone-detail-no2',
    data: '- ppb',
    icon: 'co2',
    type: 'no2',
    graph: {
      minBottomValue: 0,
      minTopValue: 10,
      borderMin: 0,
      unit: 'ppb',
      edgeSpacing: 10,
      roundValues: 10,
      stepSize: 2.5,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 8,
    name: 'stone-detail-co',
    data: '- ppm',
    icon: 'co2',
    type: 'co',
    graph: {
      minBottomValue: 0,
      minTopValue: 10,
      borderMin: 0,
      unit: 'ppm',
      edgeSpacing: 10,
      roundValues: 10,
      stepSize: 2.5,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 9,
    name: 'stone-detail-hcho',
    data: '- ppb',
    icon: 'co2',
    type: 'hcho',
    graph: {
      minBottomValue: 0,
      minTopValue: 100,
      borderMin: 0,
      unit: 'ppb',
      edgeSpacing: 20,
      roundValues: 20,
      stepSize: 20,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 10,
    name: 'stone-detail-o3',
    data: '- ppb',
    icon: 'co2',
    type: 'o3',
    graph: {
      minBottomValue: 0,
      minTopValue: 100,
      borderMin: 0,
      unit: 'ppb',
      edgeSpacing: 20,
      roundValues: 20,
      stepSize: 20,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 11,
    name: 'Brightness',
    data: '- lux',
    icon: 'brightness',
    type: 'brightness',
    graph: {
      minBottomValue: 0,
      minTopValue: 600,
      borderMin: 0,
      unit: 'lux',
      edgeSpacing: 100,
      roundValues: 100,
      stepSize: 200,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 12,
    name: 'stone-detail-sound',
    data: '- dB',
    icon: 'sound',
    type: 'sound',
    graph: {
      minBottomValue: 0,
      minTopValue: 70,
      borderMin: 0,
      unit: 'dB',
      edgeSpacing: 20,
      roundValues: 20,
      stepSize: 20,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
  {
    id: 13,
    name: 'stone-detail-energy',
    data: '- kWh',
    icon: 'energy',
    type: 'energy',
    graph: {
      minBottomValue: 0,
      minTopValue: 600,
      borderMin: 0,
      unit: 'kWh',
      edgeSpacing: 100,
      roundValues: 100,
      stepSize: 200,
      heatmapColorGradient: [
        { r: 246, g: 247, b: 249 },
        { r: 84, g: 160, b: 255 },
      ],
    },
  },
];
