import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { LocationService } from '@spaceti/core/common';
import { Observable } from 'rxjs';

import * as actions from './../actions';
import { AccountService } from '../services';
import { InitialAppState } from '../models';

@Injectable()
export class QRLoginGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private locationService: LocationService,
    private store: Store<InitialAppState>
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    //  this.location.replaceState(window.location.pathname);
    this.locationService.qr = true;
    const organization = segments[0]?.path;

    if (!organization) {
      this.accountService.logout();
    }

    this.locationService.setOrganization(organization);

    const token =
      this.router.getCurrentNavigation()?.extractedUrl?.queryParams
        ?.accessToken;
    const integration =
      this.router.getCurrentNavigation()?.extractedUrl?.queryParams
        ?.integration;
    const spaceId =
      this.router.getCurrentNavigation()?.extractedUrl?.queryParams?.space_id;

    if (integration) {
      localStorage.setItem('integration', integration);
    }

    if (token) {
      if (this.accountService.login(token)) {
        this.store.dispatch(actions.LoadRuntimeInfoAction({ spaceId }));

        const location =
          this.router
            .getCurrentNavigation()
            .extractedUrl?.root?.children?.primary?.segments?.map(
              (segment) => segment.path
            ) || [];
        const queryParams =
          this.router.getCurrentNavigation().extractedUrl?.queryParams;
        queryParams.accessToken = undefined;

        return this.router.createUrlTree(location, {
          queryParams,
        });
      } else {
        this.locationService.redirectToLogin(spaceId);
      }
    } else if (this.accountService.isSignedIn()) {
      this.store.dispatch(actions.LoadRuntimeInfoAction({ spaceId }));
      this.store.dispatch(actions.LoadAccountAction());
      return true;
    }
    this.locationService.redirectToLogin(spaceId);
  }

  canActivate() {
    return true;
    //return this.router.createUrlTree(['realtime-view/map'], { queryParams: {} });
  }
}
