export const spaceDetailsTabs = [
  { id: 0, title: 'left-menu-general', slug: 'general' },
  {
    id: 1,
    title: 'buildings-details-meetings',
    slug: 'meeting_rooms',
    booking_type: 'meeting',
  },
  {
    id: 2,
    title: 'global-booking-title',
    slug: 'booking',
    booking_type: 'booking',
  },
  {
    id: 3,
    title: 'global-booking-title',
    slug: 'event',
    booking_type: 'event',
  },
];

export const additionalSpaceType = {
  id: null,
  title: 'buildings-space-types-all',
};

export const additionalFloor = {
  id: null,
  title: 'buildings-floors-all',
};
