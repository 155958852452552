import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { AnalyticsInfo, IAccount, RuntimeInfo, UserRoleModel } from '@spaceti/models';

export const getRuntime = (x) => x.runtimeInfo as RuntimeInfo;

export const getRouterState = (x) => x.router as RouterReducerState;

export const getAccount = (x) => x.account.data as IAccount;

export const getAnalyticsInfo = (x) => x.analytics_info.data as AnalyticsInfo;

export const getAllUserRoles = (x) => x.userRoles as Array<UserRoleModel>;

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors();
