import { Observable } from 'rxjs';

export enum IntersectionStatus {
  Visible = 'visible',
  NotVisible = 'notVisible',
}

export const fromIntersectionObserver = (
  element: HTMLElement,
  config: IntersectionObserverInit,
  debounce = 0
) =>
  new Observable<IntersectionStatus>((subscriber) => {
    const intersectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          const isEntryVisible = entry.isIntersecting;

          if (isEntryVisible) {
            subscriber.next(IntersectionStatus.Visible);
          } else {
            subscriber.next(IntersectionStatus.NotVisible);
          }
        });
      },
      config
    );

    intersectionObserver.observe(element);

    return {
      unsubscribe() {
        intersectionObserver.disconnect();
      },
    };
  });
